import React from "react";

const ProjectOhua = () => {

    return (
        <div>
            OHUA
        </div>
    )
}

export default ProjectOhua;